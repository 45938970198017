import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentDisplay from '../components/content-display/content-display';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';
import SlideshowSmall from '../components/slideshow-small/slideshow-small';
import { useCurrentFilter } from '../hooks/use-current-filter';
import { get } from '../helpers/hooks-helpers';

const News = ({ data }) => {
  const content = data.nodeNews;
  // For the sidebar's slideshow-small component, we want to show all content added to the
  // Side Bar Promo vocabulary that is not expired.
  const sidebarData = useCurrentFilter(get(data, 'SidebarPromo.relationships.field_promoted_content', []));
  // Pass content into the sidebar. I'm limiting this to 5 upcoming events.
  content.sidebarContent = (
    <SlideshowSmall data={sidebarData.slice(0, 5)} />
  );

  const image = {
    src:
      content.relationships.field_image &&
      content.relationships.field_image.max_1600_10_7
        ? content.relationships.field_image.max_1600_10_7.childImageSharp
          .resolutions.src
        : null,
    alt:
      content.field_image
        ? content.field_image.alt
        : null
  };

  // Reformat the metatag data from Drupal in a way we can use.
  const metatags = content.metatag.reduce((accumulator, currentVal) => {
    return {
      ...accumulator,
      [currentVal.attributes.name]: currentVal.attributes.content
    };
  }, {});

  return (
    <DefaultLayout data={data}>
      <SEO
        {...metatags}
        siteConfig={data.site.siteMetadata}
        pageUrl={content.path.alias}
        image={image}
      />
      <ContentDisplay {...content} imageLocation="below" />
    </DefaultLayout>
  );
};

News.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query NewsQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    nodeNews(path: { alias: { eq: $slug } }) {
      title
      drupal_id
      path {
        alias
      }
      created
      body {
        processed
        summary
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
      field_image {
        alt
      }
      relationships {
        field_image {
          max_1600_10_7 {
            childImageSharp {
              # https://www.gatsbyjs.org/packages/gatsby-image/?=#how-to-use
              resolutions(width: 470, height: 330, quality: 90) {
                ...GatsbyImageSharpResolutions
              }
            }
          }
          max_1600_16_9 {
            childImageSharp {
              # https://www.gatsbyjs.org/packages/gatsby-image/?=#how-to-use
              sizes(maxWidth: 992, maxHeight: 558) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
    SidebarPromo: taxonomyTermPromotion(
      drupal_id: { eq: "4a1f98b8-f191-496d-ac7d-db604fcd6840" }
    ) {
      ...Promoted
    }
  }
`;

export default News;
